import React, { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "react-data-table-component";
import FilterComponent from "../../utils/FilterComponent";
import { customTableStyles } from "../../utils/CommanTableStyle";
import { getInvoiceTemplate } from "../../redux/InvoiceTemplateSlice";
import { useDispatch, useSelector } from "react-redux";
import { errorToast, successToast } from "../../utils/Helper";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { GlobalConstants } from "../../utils/GlobalConstants";
import moment from "moment";
import Loader from "../../utils/Loader";
import Cookie from "js-cookie";

var xpath = require("xpath"),
  dom = require("xmldom").DOMParser;
function InvoiceTemplateList() {
  //Redux code from InvoiceTemplateSlice
  const dispatch = useDispatch();
  const InvoiceData = useSelector((state) => state.InvoiceTemplate);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // old language variable
  const [text_invoice_template, setText_invoice_template] =
    useState("Invoice Templates");
  const [text_search_template_here, setText_search_template_here] = useState(
    "Search Template Here..."
  );
  const [text_create, setText_create] = useState("Create");
  const [text_temp_name, setText_temp_name] = useState("Template Name");
  const [btn_view, setbtn_view] = useState("View");
  const [text_active, setText_active] = useState("Active");
  const [text_deactive, setText_deactive] = useState("Deactive");
  const [
    salaryBreakupTypeEffectiveDateHead,
    setSalaryBreakupTypeEffectiveDateHead,
  ] = useState("Effective Date");
  const [text_status, settext_status] = useState("Status");
  // new language variable
  const [text_Sno, setText_Sno] = useState("Sr No");
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (InvoiceData?.length === 0) {
      setIsLoading(true);

      Promise.all([dispatch(getInvoiceTemplate())]).then(() =>
        setTimeout(() => {
          setIsLoading(false);
        }, 1500)
      );
    } else {
      setIsLoading(false);
    }
  }, [InvoiceData?.length]);

  //Active deactive API call
  const setDefaultProfileActive = (type, _id, isActive) => {
    var _orgId = sessionStorage.getItem(
      GlobalConstants.session_current_company_id
    );

    var apiUrl =
      GlobalConstants.Cdomain +
      "/API/moramba/v3/crud/collection/invoicetamplate/addinvoicetamplate?type=" +
      type;

    let headerConfig = {
      headers: {
        accept: "application/json",
        authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    };

    var data = {
      _id: _id,
      _orgId: _orgId,
      template_id: _id,
      isActive: isActive === true ? false : true,
    };

    axios
      .post(apiUrl, data, headerConfig)
      .then(function (response) {
        dispatch(getInvoiceTemplate());
        var res = response.data;
        successToast(res.massage);
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error.massage);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("username");
          Cookie.remove("user_id");
          Cookie.remove("AdminFName");
          Cookie.remove("AdminLName");
          Cookie.remove("token");
          window.location.replace("/");
        }
      })
      .then(function () {
        // always executed
      });
  };

  const [pageCount, setPageCount] = useState(1);

  const columns = [
    {
      name: <>{text_Sno}</>,
      selector: (row, index) => (pageCount - 1) * 10 + index + 1,
      width: "58px",
    },
    {
      name: <>{text_temp_name}</>,
      sortable: true,
      selector: (row) => row.templatename,
    },
    {
      name: <>{text_status}</>,
      sortable: false,

      selector: (row) => (
        <button
          className={row.isActive === true ? "ActiveBtn" : "DeactiveBtn"}
          disabled
          onClick={(event) => [
            setDefaultProfileActive("active", row._id, row.isActive),
          ]}
        >
          {row.isActive === true ? <>{text_active}</> : <>{text_deactive}</>}
        </button>
      ),
    },
    {
      name: <>{salaryBreakupTypeEffectiveDateHead}</>,
      sortable: true,
      selector: (row) => moment(row.effectiveDate).format("MMM DD,yyyy"),
    },
    {
      name: <>{btn_view}</>,
      selector: (row) => (
        <Link to="/invoice/template/view" state={{ data: row }}>
          <button className="ViewBtn">{btn_view}</button>
        </Link>
      ),
    },
  ];
  const filteredItems = InvoiceData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  const TablePaginationActions = (count) => {
    setPageCount(count);
  };
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        PlaceHolder={text_search_template_here}
      />
    );
  }, [filterText, resetPaginationToggle, text_search_template_here]);

  const SetLanguageText = () => {
    var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
    var doc = new dom().parseFromString(xml);
    setText_search_template_here(
      xpath.select1("/resources/string[@name='text_search_template_here']", doc)
        .firstChild.data
    );
    setText_create(
      xpath.select1("/resources/string[@name='text_create']", doc).firstChild
        .data
    );
    setText_temp_name(
      xpath.select1("/resources/string[@name='text_temp_name']", doc).firstChild
        .data
    );
    setSalaryBreakupTypeEffectiveDateHead(
      xpath.select1(
        "/resources/string[@name='salaryBreakupTypeEffectiveDateHead']",
        doc
      ).firstChild.data
    );
    setText_active(
      xpath.select1("/resources/string[@name='text_active']", doc).firstChild
        .data
    );
    setbtn_view(
      xpath.select1("/resources/string[@name='btn_view']", doc).firstChild.data
    );
    setText_deactive(
      xpath.select1("/resources/string[@name='text_deactive']", doc).firstChild
        .data
    );
    settext_status(
      xpath.select1("/resources/string[@name='text_status']", doc).firstChild
        .data
    );
    setText_invoice_template(
      xpath.select1("/resources/string[@name='text_invoice_template']", doc)
        .firstChild.data
    );
    setText_Sno(
      xpath.select1("/resources/string[@name='text_Sno']", doc).firstChild.data
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log(err);
    }
  }, []);

  //token Expire
  // useEffect(() => {
  //   var u_token = sessionStorage.getItem("token");
  //   if (u_token === "" || u_token === null) {
  //     navigate("/");
  //   }
  // });
  return (
    <>
      <h3 className="HeadingText mt-1 mb-1 text-center p-2">
        {text_invoice_template}
      </h3>
      <div className="container containerBox mt-4 p-2">
        <div className="d-flex justify-content-end">
          <div className="d-flex flex-row Searchbar  search_temp">
            <SearchIcon />
            {subHeaderComponent}
          </div>{" "}
          &nbsp;&nbsp;
          <Link to="/invoice/template/create">
            <button className=" CreateBtn">+ {text_create}</button>
          </Link>
        </div>
        {IsLoading ? (
          <div className="mt-5 mb-5 d-flex justify-content-center">
            <Loader />
          </div>
        ) : (
          <div className="mt-3">
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              fixedHeader
              selectableRowsHighlight
              highlightOnHover
              onChangePage={TablePaginationActions}
              customStyles={customTableStyles}
            />
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
}

export default InvoiceTemplateList;
