import { AppBar } from "@mui/material";
import React, { useState ,useEffect} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ExpenseTemplateList from "../ExpenseTemplate/ExpenseTemplateList";
import Header from "../Header/Header";
import InventoryTemplateList from "../InventoryTemplate/InventoryTemplateList";
import { useLocation } from "react-router-dom";
import SubscriptionTemplateList from "../SubscriptionTemplate/SubscriptionTemplateList";
import BillTemplateList from "../BillTemplate/BillTemplateList";
import InvoiceTemplateList from "../InvoiceTemplate/InvoiceTemplateList";
import { GlobalConstants } from "../../utils/GlobalConstants";
import { useNavigate } from "react-router-dom";
var xpath = require("xpath"),
dom = require("xmldom").DOMParser;

function GeneralTemplate() {
  const data = useLocation();
  const dataValue = data.state !== null ? data.state.data : 0;
  const [selectedTab, setSelectedTab] = useState(dataValue);
  //Language Variables Start
  //Old Variables
  const [text_bill_template, setText_bill_template] = useState("Bill Template");
  const [text_invoice_template, setText_invoice_template] =
    useState("Invoice Template");
  //New Variables
  const [title_ExpTemp, setTitle_ExpTemp] = useState("Expense Template");
  const [title_InvenTemp, setTitle_InvenTemp] = useState("Inventory Template");
  const [title_SubTemp, setTitle_SubTemp] = useState("Subscription Template");
  //Language Variables Ends
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    if (dataValue !== null) {
      setSelectedTab(newValue);
    } else {
      setSelectedTab(dataValue);
    }
  };

  const SetLanguageText = () => {
    var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
    var doc = new dom().parseFromString(xml);

    setText_invoice_template(
      xpath.select1("/resources/string[@name='text_invoice_template']", doc)
        .firstChild.data
    );
    setText_bill_template(
      xpath.select1("/resources/string[@name='text_bill_template']", doc)
        .firstChild.data
    );
    setTitle_ExpTemp(
      xpath.select1("/resources/string[@name='title_ExpTemp']", doc).firstChild
        .data
    );
    setTitle_InvenTemp(
      xpath.select1("/resources/string[@name='title_InvenTemp']", doc)
        .firstChild.data
    );
    setTitle_SubTemp(
      xpath.select1("/resources/string[@name='title_SubTemp']", doc).firstChild
        .data
    );
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log(err);
    }
  },[]);

  //token Expire
  // useEffect(() => {
  //   var u_token = sessionStorage.getItem("token");
  //   if (u_token === "" || u_token === null) {
  //     navigate("/");
  //   }
  // });

  return (
    <>
      <Header />
      <div className="container login_background">
        <AppBar position="static" className="tab_bar mt-5 appscroll" id="mobBoxtab">
          <Tabs value={selectedTab} onChange={handleChange} variant="scrollable">
            <Tab label={title_ExpTemp} className="Login_text" />
            <Tab label={title_InvenTemp} className="Login_text" />
            <Tab label={title_SubTemp} className="Login_text" />
            <Tab label={text_bill_template} className="Login_text" />
            <Tab label={text_invoice_template} className="Login_text" />
          </Tabs>
        </AppBar>

        {selectedTab === 0 && <ExpenseTemplateList />}
        {selectedTab === 1 && <InventoryTemplateList />}
        {selectedTab === 2 && <SubscriptionTemplateList />}
        {selectedTab === 3 && <BillTemplateList />}
        {selectedTab === 4 && <InvoiceTemplateList />}
      </div>
    </>
  );
}
export default GeneralTemplate;
