import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { AppBar } from "@mui/material";
import PersonalInsurance from "./PersonalInsurance";
import BussinessInsurance from "./BussinessInsurance";
import FoodBenefits from "./FoodBenefits";
import Header from "../Header/Header";
import TravelBenefits from "./TravelBenefits";
import { useDispatch, useSelector } from "react-redux";
import { getOrgSelectedBenefits } from "../../redux/OrgSelectedBenefits";
import MyCustomeBenefits from "./MyCustomeBenefits";
import { ToastContainer } from "react-toastify";
import { GlobalConstants } from "../../utils/GlobalConstants";
var xpath = require("xpath"),
  dom = require("xmldom").DOMParser;

function CmpSelectedBenefits() {
  const dispatch = useDispatch();
  const OrgBeneiftsList = useSelector((state) => state.OrgSelectedBenefits);

  // language variable
  const [text_personalins, setText_personalins] =
    useState("Personal Insurance");
  const [text_businessins, setText_businessins] =
    useState("Business Insurance");
  const [text_foodins, setText_foodins] = useState("Food Benefits");
  const [text_TravelBenefits, settext_TravelBenefits] =
    useState("Travel Benefits");
  const [text_Custombenefits, settext_Custombenefits] = useState(
    "My Custom Benefits"
  );
  const [text_Combenefits, settext_Combenefits] = useState(
    "Company Selected Benefits" 
  )

  //variable
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (OrgBeneiftsList?.length === 0) {
      dispatch(getOrgSelectedBenefits());
    }
  }, []);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const SetLanguageText = () => {
    var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
    var doc = new dom().parseFromString(xml);
  setText_personalins(
    xpath.select1("/resources/string[@name='text_personalins']", doc).firstChild
      .data
  );
  setText_businessins(
    xpath.select1("/resources/string[@name='text_businessins']", doc).firstChild
      .data
  );
  setText_foodins(
    xpath.select1("/resources/string[@name='text_foodins']", doc).firstChild
      .data
  );
  settext_TravelBenefits(
    xpath.select1("/resources/string[@name='text_TravelBenefits']", doc).firstChild
      .data
  );
  settext_Combenefits(
    xpath.select1("/resources/string[@name='text_Combenefits']", doc).firstChild
      .data
  );
  settext_Custombenefits(
    xpath.select1("/resources/string[@name='text_Custombenefits']", doc).firstChild
      .data
  );
  }
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log(err);
    }
  },[]);
  return (
    <>
      <Header />
      <h2 className="text-center mt-5">
        <u>{text_Combenefits}</u>
      </h2>
      <AppBar position="static" className="tab_bar" id="mobBoxtab">
        <Tabs value={selectedTab} onChange={handleChange} variant="scrollable">
          <Tab label={text_personalins} className="Login_text" />
          <Tab label={text_businessins} className="Login_text" />
          <Tab label={text_foodins} className="Login_text" />
          <Tab label={text_TravelBenefits} className="Login_text" />
          <Tab label={text_Custombenefits} className="Login_text" />
        </Tabs>
      </AppBar>
      {selectedTab === 0 && <PersonalInsurance />}
      {selectedTab === 1 && <BussinessInsurance />}
      {selectedTab === 2 && <FoodBenefits />}
      {selectedTab === 3 && <TravelBenefits />}
      {selectedTab === 4 && <MyCustomeBenefits />}
      <ToastContainer />
    </>
  );
}

export default CmpSelectedBenefits;
