import { Oval } from "react-loader-spinner";
import "./Loader.css"
export default function Loader() {
  return (
    <>
      {/* <Oval
        height={60}
        width={60}
        color="#4fa94d"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#6d9886"
        strokeWidth={2}
        strokeWidthSecondary={2}
      /> */}
      <div className="loader_box">

      <div className="loader"></div>
      </div>
    </>
  );
}
